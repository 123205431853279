import React, { useState, useCallback } from 'react'

import ReactLoading from 'react-loading';
import Modal from 'react-modal';

import { useMutation } from "@apollo/client";

import PageHeader from '../../components/PageHeader';

import { startAndEnd } from '../../utils/truncate'

import { CANCEL_CONTRACTOR_POLICY } from './mutations';
import { environments } from '../../constants/environments';

const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

let contentStyle = {
  width                 : '40%',
  top                   : '50%',
  left                  : '50%',
  right                 : 'auto',
  bottom                : 'auto',
  marginRight           : '-50%',
  transform             : 'translate(-50%, -50%)',
  boxShadow             : '2px 2px 8px 0px rgba( 0, 0, 0, 0.2 )',
}

let modalStyles = {
  content : contentStyle,
  overlay : {
    backgroundColor : 'rgba(0, 0, 0, 0.5)'
  } 
};

function CancelPolicyModal(props){
  const [cancelContractorPolicy, { loading, error, reset }] = useMutation(CANCEL_CONTRACTOR_POLICY, {
    onCompleted: ()=>{
      props.closeModal();
      props.setConfirmedCancelledPolicies([...props.confirmedCancelledPolicies, props.policy])
      props.setSuccessMessage(`The policy with the id matching ${props.policy} was successfully cancelled.`)
      reset()
    },
    refetchQueries: ['Contractor', 'ContractorByID']
  });

  const renderLoading = () => (
    <ReactLoading type={'spin'} color={'#cccccc'} className="center" />
  )

  const renderError = () => (
    <div className="br2 f6 flex items-center justify-center pa3 bg-lightest-blue navy">
      <span className="lh-title ml3">There was an error cancelling the policy. Please try again.</span>
    </div>
  )

  const renderCancelConfirmation = (props) => (
    <div className="roboto">
      <PageHeader title={'Confirm Policy Cancellation'} />
      {error ? renderError() : null}
      <div className="f6 fw5 db mb2 mt3">
        Are you sure you want to cancel the policy with the following id <b className="red">{props.policy}</b>? 
        This operation can't be undone.
      </div>
      <div className="mv4">
        <button 
          className="f6 link dim br2 ph3 pv2 mb2 ml2 dib white bg-white b--light-red red ba pointer fr"
          type="submit"
          onClick={(e) => {
            const policy = props.policy
            cancelContractorPolicy({
              variables: {policy}
            })
          }}
          >
          YES – CANCEL the policy!
        </button>
        <button 
          onClick={props.closeModal}
          className="f6 link dim br2 ba ph3 pv2 mb2 dib black pointer fr"
          type="submit">
          Cancel
        </button>
      </div>
    </div>
  )

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={modalStyles}
      contentLabel="Cancel insurance coverage."
    >
      {
        loading ? renderLoading() : renderCancelConfirmation(props)
      }
    </Modal>
  )
}

function CoverageCancelOnHoverBtn(props){
  const [hover, setHover] = useState(false);

  const { 
    effectiveDate,
    isAdmin,
    policyPublicId,
    isActive,
    setCancelPolicyModalValues
  } = props

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  const adminOnlyCancelBtn = () => {
    return (
      <button 
        type="button"
        onClick={(e) => {
          e.stopPropagation()
          setCancelPolicyModalValues({cancelPolicyModalIsOpen: true, policy: policyPublicId, confirmed: false })
        }}
        className="outline-0 pointer br2 ba b--black-20 bg-white pv2 ph3 f7 lh-title bg-animate border-box w-100">
        <img className="dib v-btm w1" src="/cancel.svg" alt="Cancel" /> Cancel Policy
      </button>
    )
  }

  const renderActiveAsOf = () => (
    <button className="outline-0 br2 ba b--black-20 bg-white pv2 ph3 f7 lh-title bg-animate border-box w-100">
      {effectiveDate}
    </button>
  )

  return (
    <div
      className="dib w-100"
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      role="button"
      tabIndex="-3"
    >
      {hover && isAdmin && isActive ? adminOnlyCancelBtn() : renderActiveAsOf()}
    </div>
  )
}

function ActiveCoverageTable(props){
  const {
    policies,
    setConfirmedCancelledPolicies,
    confirmedCancelledPolicies,
    isAdmin,
    setSuccessMessage,
    token
  } = props

  const [cancelPolicyModalValues, setCancelPolicyModalValues] = useState({
    cancelPolicyModalIsOpen: false,
    policy: ''
  })
  
  const handleEsignClick = useCallback(async (event, url) => {
    event.stopPropagation();
    
    try {
      const token = props.token;
      
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Ten99Policy-Environment': localStorage.getItem('Ten99PolicyEnvironment') || environments.PRODUCTION,
          'Ten99Policy-Organization': localStorage.getItem("Ten99PolicyOrganization") || "",
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch signed URL');
      }

      const data = await response.json();
      const signedUrl = data.signed_url;
      
      window.open(signedUrl, '_blank');

    } catch (error) {
      console.error('Error fetching e-signature document:', error);
      // Optionally show error to user
    }
  }, [props.token]);

  const renderEsignLink = (esignPdfUrl) => {
    if (!esignPdfUrl) return null;

    return (
      <button
        onClick={(e) => handleEsignClick(e, esignPdfUrl)}
        className="dark-gray pointer bn bg-transparent pa0"
      >
        <img className="dib v-btm pr1 ph3 w1" src="/document.svg" alt="e-signature" />
      </button>
    );
  };

  const renderActiveCoverageRows = (policies) => {
    const rows = policies.map((obj) => {
      const policy = obj?.edges[0]?.node
      const jobCategory = obj?.jobCategory

      const coverageTypes = obj?.coverageTypes
      const effectiveDate = new Date(policy?.effectiveDate).toLocaleDateString("en-US", options);
      const cancelledDate = policy?.cancelledDate ? new Date(policy?.cancelledDate).toLocaleDateString("en-US", {year: 'numeric', month: 'short', day: 'numeric'}) : undefined;
      
      const esignPdfUrl = policy?.esignDisclosures?.edges[0]?.node?.protectedEsignUrl
      
      const confirmedCancelledPolicy = confirmedCancelledPolicies.includes(policy?.publicId)
      const policyIsActive = policy?.isActive && !confirmedCancelledPolicy
      
      return (
        <tr className="bb" key={policy?.publicId}>
          <td className="tl ph2 pv3 f7">{policy?.publicId}</td>
          <td className="tl ph2 pv3 f7">{startAndEnd(jobCategory)} </td>
          <td className="tl ph2 pv3 f7"> {coverageTypes} </td>
          <td className="tl ph2 pv3 f7">
            {policyIsActive ? 
              <span className="tl f7 mv1 ph2 dib green">Active</span> :
              <div className="cf relative">
                <span className="tl f7 mv1 ph2 dib red">Cancelled
                  {cancelledDate &&  <span className="db red tc" style={{fontSize: '10px'}}>{`${cancelledDate}`}</span>}
                </span>
              </div>
            }
          </td>
          <td className="tl ph2 pv3 f7">
            <CoverageCancelOnHoverBtn 
              policyPublicId={policy?.publicId}
              isActive={policyIsActive}
              setCancelPolicyModalValues={setCancelPolicyModalValues}
              effectiveDate={effectiveDate} 
              isAdmin={isAdmin} 
            />
          </td>
          <td className="tl ph2 pv3 f7 tc">
            {renderEsignLink(esignPdfUrl)}
          </td>
        </tr>
      )})

    return rows
  }

  const renderActiveCoverageTable = (policies) => {
    return (
      <div>
        <table className="w-100 bb b--light-gray collapse ph2 mt4 mb3">
        <thead>
          <tr className="bb b--light-gray">
          <th className="tl pv3 ph2 fw5 f7 ttu w-10">Policy ID</th>
            <th className="tl pv3 ph2 fw5 f7 ttu w-25">Job Category</th>
            <th className="tl pv3 ph2 fw5 f7 ttu w-15">Coverage (Work / Home)</th>
            <th className="tl pv3 ph2 fw5 f7 ttu w-15">Status</th>
            <th className="tl pv3 ph2 fw5 f7 ttu w-15">Active as of</th>
            <th className="tl pv3 ph2 fw5 f7 ttu w-15 tc">e-Signature</th>
          </tr>
        </thead>
        <tbody>
          {renderActiveCoverageRows(policies)}
        </tbody>
        </table>
      </div>
    )
  }

  
  return (
    <>
      {
      policies.length > 0 && 
        <div className="mt5">
          <h3 className="dark-gray bb b--black-10 mt0 pb3">
            Active Coverage
          </h3>
          <div className="gray f6 fw2 lh-copy">
            {renderActiveCoverageTable(policies)}
          </div>
        </div>
      }
      <CancelPolicyModal 
        openModal={() => setCancelPolicyModalValues({...cancelPolicyModalValues, cancelPolicyModalIsOpen: true})}
        closeModal={() => setCancelPolicyModalValues({...cancelPolicyModalValues, cancelPolicyModalIsOpen: false})}
        modalIsOpen={cancelPolicyModalValues.cancelPolicyModalIsOpen}
        policy={cancelPolicyModalValues.policy}
        confirmedCancelledPolicies={confirmedCancelledPolicies}
        setConfirmedCancelledPolicies={setConfirmedCancelledPolicies}
        setSuccessMessage={setSuccessMessage}
        token={token}
      />
    </>
  )
}

export default ActiveCoverageTable;