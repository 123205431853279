import React, { useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import classNames from 'classnames';

import {centsToDollars} from '../utils/currency'

import MainWrapper from '../components/MainWrapper';
import AdminDisclaimer from '../components/AdminDisclaimer';
import InvoicePdfModal from './ReportingAdminPage/InvoicePdfModal';

const OUTSTANDING_TASKS_QUERY = gql`
  query OutstandingTasks {
    outstandingTasks {
      id
      type
      description
      status
      createdAt
      ... on EmailAgencyPayInvoiceTask {
        clientName
        totalAmount
        clientNameMismatches
        similarInvoicesWithoutPo {
          contractor {
            publicId
            unredactedDict
          } 
        }
        billToPrimaryEmail
        billToCcEmail
        poContactName
        poContactEmail
        poNumber
        invoices {
          paidInFull
          grossPay
          feeAmount
          fee {
            edges {
              node {
                paidInFull
              }
            }
          }
          publicId
          purchaseOrderId
          premiumDue
          contractor {
            publicId
            unredactedDict
            poContactName
            poContactEmail
          }
          job {
            publicId
            name
            entity {
              publicId
              name
            }
            poContactName
            poContactEmail
          }
          tenant {
            organization {
              publicId
              name
              companyAddress {
                line1
                line2
                line3
                locality
                region
                postalcode
              }
            }
          }
        }
      }
    }
  }
`;

const getContractorName = (unredactedDict) => {
  const contractor = JSON.parse(unredactedDict)
  return `${contractor.first_name} ${contractor.last_name}`;
}

// Helper function to get PO contact details
const getPOContactDetails = (invoice) => {
  const poContactName = invoice.job.poContactName || invoice.contractor.poContactName;
  const poContactEmail = invoice.job.poContactEmail || invoice.contractor.poContactEmail;
  return { poContactName, poContactEmail };
}

const TasksAdmin = (props) => {
  const { loading, error, data, refetch } = useQuery(OUTSTANDING_TASKS_QUERY);
  const [pdfModalIsOpen, setPdfModalIsOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedInvoices, setSelectedInvoices] = useState({});

  useEffect(() => {
    if (data && data.outstandingTasks) {
      const initialSelectedInvoices = {};
      data.outstandingTasks.forEach(task => {
        task.invoices.forEach(invoice => {
          initialSelectedInvoices[invoice.publicId] = true;
        });
      });
      setSelectedInvoices(initialSelectedInvoices);
    }
  }, [data]);

  const openInvoicePdfModal = (task) => {
    const purchaseOrderAmount = calculatePurchaseOrderAmount(task, selectedInvoices);
    setSelectedTask({ ...task, purchaseOrderAmount });
    setPdfModalIsOpen(true);
  };

  const closeInvoicePdfModal = () => {
    setPdfModalIsOpen(false);
    setSelectedTask(null);
  };

  const handleInvoiceSelection = (invoice, isSelected) => {
    setSelectedInvoices(prev => ({...prev, [invoice.publicId]: isSelected}));
  };

  const getRowClassName = (isSelected) => {
    return classNames(
      'bb b--light-gray pointer',
      {
        'bg-washed-blue': isSelected,
        'hover-bg-washed-blue': !isSelected
      }
    );
  };

  const calculateTaskTotal = (task) => {
    return task.invoices.reduce((total, invoice) => {
      if (selectedInvoices[invoice.publicId]) {
        return total + invoice.premiumDue + invoice.feeAmount;
      }
      return total;
    }, 0);
  };

  const calculatePurchaseOrderAmount = (task, selectedInvoices) => {
    return task.invoices.reduce((total, invoice) => {
      if (selectedInvoices[invoice.publicId]) {
        return total + invoice.premiumDue;
      }
      return total;
    }, 0);
  };

  const renderInvoicePdfButton = (task, taskTotal) => {
    const isDisabled = taskTotal === 0;
    return (
      <button
        className={classNames(
          "absolute top-0 right-0 mt3 mr3 outline-0 br2 ba pv2 ph3 f5 bg-animate border-box",
          {
            "b--black-20 bg-white pointer": !isDisabled,
            "b--black-10 bg-light-gray gray": isDisabled
          }
        )}
        onClick={() => openInvoicePdfModal(task)}
        disabled={isDisabled}
      >
        Invoice PDF
      </button>
    );
  };

  const renderTaskCount = () => {
    if (!data || !data.outstandingTasks) return null;
    
    const count = data.outstandingTasks.length;
    return (
      <div className="absolute top-0 right-0 mt2 mr0 pa2 f6 fw6">
        Total: {count}
      </div>
    );
  };

  const renderWarnings = (task) => {
    if (
      (!task.clientNameMismatches || task.clientNameMismatches.length === 0) &&
      (!task.similarInvoicesWithoutPo || task.similarInvoicesWithoutPo.length === 0)
    ) {
      return null;
    }
    
    return (
      <div className="flex bg-washed-yellow mb2 br2 ba b--yellow pa2">
        <div className="pv2 flex items-start">
          <img src="/alert.svg" alt="Alert" className="w1 h1" />
        </div>
        <div className="pa2 flex-grow-1">
          {task.clientNameMismatches && task.clientNameMismatches.length > 0 && (
            <div className="mb2">
              <p className="f6 ma0">Client name mismatch: {task.clientNameMismatches.join(', ')}</p>
            </div>
          )}
          {task.similarInvoicesWithoutPo && task.similarInvoicesWithoutPo.length > 0 && (
            <div>
              <p className="f6 ma0">Contractors with similar jobs without PO: &nbsp;
              <span className="fw5">{
                task.similarInvoicesWithoutPo.map(invoice => {
                  const unredactedDict = JSON.parse(invoice.contractor.unredactedDict);
                  return `${unredactedDict.first_name} ${unredactedDict.last_name}`;
                }).join(', ')
              }</span>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  
  return (
    <MainWrapper isTestView={props.isTestView}>
      <AdminDisclaimer />
      <header className="mb3 relative">
        <h1 className="fw3 dark-gray mt0 mb4">Outstanding Tasks</h1>
        {renderTaskCount()}
      </header>
      <ul className="list pl0">
        {data.outstandingTasks.map(task => {
          const taskTotal = calculateTaskTotal(task);
          return (
            <li key={task.id} className="mb3 pa3 ba b--black-10 br2 relative">
              <h2 className="f5 fw6">{task.description}</h2>
              <p className="f6"><strong>Subject:</strong> PO#{task.poNumber} {task.clientName}, {centsToDollars(taskTotal)}</p>
              <p className="f6"><strong>To:</strong> {task.billToPrimaryEmail}</p>
              <p className="f6"><strong>CC:</strong> {task.billToCcEmail} {task.poContactEmail}</p>
              {renderInvoicePdfButton(task, taskTotal)}
              {task.invoices && task.invoices.length > 0 && (
                <div>
                  <table className="w-100 ba b--light-gray collapse ph2 mv4">
                    <thead>
                      <tr className="bb bw1 b--black-10">
                        <th className="tl pv3 ph2 fw5 f7 ttu"></th>
                        <th className="tl pv3 ph2 fw5 f7 ttu">Contractor</th>
                        <th className="tl pv3 ph2 fw5 f7 ttu">Client</th>
                        <th className="tl pv3 ph2 fw5 f7 ttu">Job Name</th>
                        <th className="tl pv3 ph2 fw5 f7 ttu">PO #</th>
                        <th className="tl pv3 ph2 fw5 f7 ttu">PO Contact Name</th>
                        <th className="tl pv3 ph2 fw5 f7 ttu">PO Contact Email</th>
                        <th className="tl pv3 ph2 fw5 f7 ttu">Premium Due</th>
                        <th className="tl pv3 ph2 fw5 f7 ttu">Platform Access Fee</th>
                      </tr>
                    </thead>
                    <tbody>
                      {task.invoices.map(invoice => {
                        const { poContactName, poContactEmail } = getPOContactDetails(invoice);
                        const isSelected = selectedInvoices[invoice.publicId] !== false;
                        return (
                          <tr 
                            key={invoice.publicId} 
                            className={getRowClassName(isSelected)}
                            onClick={() => handleInvoiceSelection(invoice, !isSelected)}
                          >
                            <td className="tl ph2 pv3 f7">
                              <input
                                type="checkbox"
                                checked={isSelected}
                                onChange={(e) => handleInvoiceSelection(invoice, e.target.checked)}
                                onClick={(e) => e.stopPropagation()}
                              />
                            </td>
                            <td className="tl ph2 pv3 f7">{getContractorName(invoice.contractor.unredactedDict)}</td>
                            <td className="tl ph2 pv3 f7">{invoice.job.entity.name}</td>
                            <td className="tl ph2 pv3 f7">{invoice.job.name}</td>
                            <td className="tl ph2 pv3 f7">{invoice.purchaseOrderId}</td>
                            <td className="tl ph2 pv3 f7">{poContactName}</td>
                            <td className="tl ph2 pv3 f7">{poContactEmail}</td>
                            <td className="tl ph2 pv3 f7">{centsToDollars(invoice.premiumDue)}</td>
                            <td className="tl ph2 pv3 f7">{centsToDollars(invoice.feeAmount)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              {renderWarnings(task)}
            </li>
          );
        })}
      </ul>
      {selectedTask && (
        <InvoicePdfModal
          jobs={selectedTask.invoices.filter(invoice => selectedInvoices[invoice.publicId]).map(invoice => invoice.job.publicId)}
          selectedContractors={selectedTask.invoices.reduce((acc, invoice) => {
            if (selectedInvoices[invoice.publicId]) {
              const job = invoice.job;
              const unredactedDict = JSON.parse(invoice.contractor.unredactedDict);
              acc[job.publicId] = {
                firstName: unredactedDict.first_name,
                lastName: unredactedDict.last_name,
                organizationName: invoice.tenant.organization.name,
                organizationAddressLine1: invoice.tenant.organization.companyAddress.line1,
                organizationAddressLine2: invoice.tenant.organization.companyAddress.line2,
                organizationAddressLine3: invoice.tenant.organization.companyAddress.line3,
                organizationLocality: invoice.tenant.organization.companyAddress.locality, 
                organizationRegion: invoice.tenant.organization.companyAddress.region, 
                organizationPostalcode: invoice.tenant.organization.companyAddress.postalcode,
                purchaseOrderId: invoice.purchaseOrderId,
                campaignName: job.entity.name,
                purchaseOrderContactName: selectedTask.poContactName,
                purchaseOrderContactEmail: selectedTask.poContactEmail,
                paidInFull: invoice.paidInFull,
                invoicePremium: centsToDollars(invoice.premiumDue),
                remuneration: centsToDollars(invoice.grossPay),
                saasFeeAmount: centsToDollars(invoice.feeAmount),
                sassFeePaidInFull: invoice.fee.edges[0].node.paidInFull,
              };
            }
            return acc;
          }, {})}
          purchaseOrderAmount={centsToDollars(selectedTask.purchaseOrderAmount)}
          setSuccessMessage={() => {}}
          modalIsOpen={pdfModalIsOpen}
          closeModal={closeInvoicePdfModal}
          contentLabel={"Verify Invoice Details"}
          refetchOutstandingTasks={refetch}
        />
      )}
    </MainWrapper>
  );
};

export default TasksAdmin;