import React, {useState} from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip'

import { useMutation } from '@apollo/client'

import LoadingSpinner from '../../components/LoadingSpinner'

import { 
  MARK_OFAC_USER_EXCEPTION,
  MARK_MANUAL_REVIEW_APPROVED,
  EXPIRE_RENEW_APPLICATION 
} from './mutations'

import { startAndEnd } from '../../utils/truncate'

import classNames from 'classnames'

function MarkExceptionOnHoverBtn(props){
  const [hover, setHover] = useState(false);
  
  const {
    url,
    status,
    isEligible,
    markOfacUserException,
    markManualReviewApproved,
    loadingOfac,
    loadingManualReview,
    isAdmin,
    ofacResults
  } = props;
  
  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  const adminOnlyExceptionBtn = () => {
    const isManualReview = !ofacResults;
    const loading = isManualReview ? loadingManualReview : loadingOfac;
    const buttonText = isManualReview ? 'Approve review' : 'Mark exception';
    const handleClick = () => {
      if (isManualReview) {
        markManualReviewApproved(url);
      } else {
        markOfacUserException(url);
      }
    };
    
    return (
      <button 
        type="button" 
        onClick={handleClick}
        className="outline-0 pointer br2 ba b--black-20 bg-white pv2 ph3 f7 lh-title bg-animate border-box w-100 red">
        {loading ? 
          <LoadingSpinner /> : 
          buttonText
        }
      </button>
    )
  }

  const renderStatus = () => (
    <>
      {!isEligible ? (<span className="w-100 lh-title red">Ineligible</span>): status }
    </>
  )

  return (
    <div
      className="dib w-100"
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      role="button"
      tabIndex="-3"
    >
      {hover && isAdmin && !isEligible ? adminOnlyExceptionBtn() : renderStatus()}
    </div>
  )
}

function ApplicationsTable(props){
  const {isAdmin, newApplicationUrls} = props
  let [urlCopied, setUrlCopied] = useState()

  const IN_PROGRESS = "In progress"
  const COMPLETE = "Complete"
  const EXPIRED = "Expired"

  const [expireRenewApplication, { loading: loadingUrl, error: errorUrl, reset: resetUrl }] = useMutation(EXPIRE_RENEW_APPLICATION, {
    onCompleted: (data) => {
      if (!data.expireRenewInsuranceApplication?.ok){
        props.setErrorMessage(data.expireRenewInsuranceApplication?.message)
        resetUrl()
      } else {
        props.setNewApplicationUrls([{
          'quoteId': data.expireRenewInsuranceApplication?.quoteId,
          'url': data.expireRenewInsuranceApplication?.url
        }, ...newApplicationUrls])
        props.setSuccessMessage(data.expireRenewInsuranceApplication?.message)
        props.setErrorMessage(false)
        resetUrl()
      }
    },
    refetchQueries: ['Contractor', 'ContractorByID']
  });

  const [markManualReviewApprovedRequest, {loading: loadingManualReview, error: errorManualReview, reset: resetManualReview}] = useMutation(MARK_MANUAL_REVIEW_APPROVED, {
    onCompleted: (data) => {
      if (!data.markManualReviewApproved?.ok){
        props.setErrorMessage(data.markManualReviewApproved?.message)
        resetManualReview()
      } else {
        props.setSuccessMessage(data.markManualReviewApproved?.message)
        resetManualReview()
      }
    },
    refetchQueries: ['Contractor', 'ContractorByID']
  });

  const [markOfacUserExceptionRequest, {loading: loadingOfac, error: errorOfac, reset: resetOfac}] = useMutation(MARK_OFAC_USER_EXCEPTION, {
    onCompleted: (data) => {
      if (!data.markOfacUserException?.ok){
        props.setErrorMessage(data.markOfacUserException?.message)
        resetOfac()
      } else {
        props.setSuccessMessage(data.markOfacUserException?.message)
        setMarkExceptions([{
          'quoteId': data.markOfacUserException?.quoteId,
          'isException': true
        }, ...markExceptions])
        resetOfac()
      }
    },
    refetchQueries: ['Contractor', 'ContractorByID']
  });

  function expireInsuranceApplication(insurance_application_url){
    expireRenewApplication({
      variables: {
        insuranceApplicationUrl: insurance_application_url,
      }
    })
  }

  function markManualReviewApproved(insurance_application_url){
    markManualReviewApprovedRequest({
      variables: {
        insuranceApplicationUrl: insurance_application_url,
      }
    })
  }

  function markOfacUserException(insurance_application_url){
    markOfacUserExceptionRequest({
      variables: {
        insuranceApplicationUrl: insurance_application_url,
      }
    })
  }

  function afterHideUrlTip() {
    setUrlCopied(false)
  }

  const renderInsuranceApplicationUrl = (applicationUrl) => {
    return (
      <div className="gray f7 fw2 lh-copy">
        <div className="cf relative">
          <CopyToClipboard text={applicationUrl} onCopy={() => setUrlCopied(true)}>
            <div data-tip data-for='url_copy' className="dib pointer f7 fw5 fl bg-white pv2 lh-copy">
            {applicationUrl}
            </div>  
          </CopyToClipboard>
          <ReactTooltip 
            id='url_copy' 
            place='bottom'
            type='info'
            afterHide={afterHideUrlTip} 
            effect='solid'>
            <span>{urlCopied ? 'Copied!' : 'Click to copy'}</span>
          </ReactTooltip>
        </div>      
      </div>
    )
  }

  const renderApplicationRows = (applications) => {
    function isComplete(status){
      return [COMPLETE].includes(status)
    }

    function isGreenStatus(status){
      return [COMPLETE, IN_PROGRESS].includes(status)
    }

    const rows = applications.map((obj) => {
      const isExpired = new Date(obj?.quoteEndDate) < new Date() && obj?.status !== COMPLETE;
      const displayStatus = isExpired ? EXPIRED : obj?.status;
      
      return (
        <tr className="bb" key={obj?.quoteId}>
          <td className="tl ph2 pv3 f7">{startAndEnd(obj?.jobCategory)} ({obj?.coverageTypes})</td>
          <td className="tl ph2 pv3 f7">{renderInsuranceApplicationUrl(obj?.url)}</td>
          <td className={classNames("tl ph2 pv3 f7", {"green": isGreenStatus(displayStatus)}, {"red": !isGreenStatus(displayStatus)})}>
            <MarkExceptionOnHoverBtn 
              url={obj?.url}
              status={displayStatus}
              isEligible={obj?.isEligible}
              markOfacUserException={markOfacUserException}
              markManualReviewApproved={markManualReviewApproved}
              loadingOfac={loadingOfac}
              loadingManualReview={loadingManualReview}
              isAdmin={isAdmin}
              ofacResults={obj?.ofacResults}
            />
          </td>
          <td className="tl ph2 pv3 f7">{
              isAdmin && !isComplete(displayStatus) && 
              (
                <button 
                  type="button" 
                  onClick={() => expireInsuranceApplication(obj?.url)}
                  className="fr outline-0 pointer br2 ba b--black-20 bg-white pa2 f7 lh-title bg-animate hover-bg-light-gray border-box">
                  {loadingUrl ? 
                    <LoadingSpinner /> : 
                    <><img className="dib v-btm w1" src="/refresh.svg" alt="Refresh" /></>
                  }  
                </button>
              )
            }
          </td>
        </tr>
      )
    })

    return rows
  }
  const renderApplicationTable = (applications) => {
      return (
        <div>
          <table className="w-100 bb b--light-gray collapse ph2 mt4 mb3">
          <thead>
            <tr className="bb b--light-gray">
              <th className="tl pv3 ph2 fw5 f7 ttu w-20">Job Category</th>
              <th className="tl pv3 ph2 fw5 f7 ttu">URL</th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-15">Status</th>
              <th className="tl pv3 ph2 fw5 f7 ttu w-15">
              </th>
            </tr>
          </thead>
          <tbody>
            {renderApplicationRows(applications)}
          </tbody>
          </table>
        </div>
      )
    }

    return (
      <div>
        <h3 className="dark-gray bb b--black-10 mt0 pb3">
          Applications
        </h3>
        <div className="gray f6 fw2 lh-copy">
        { props.insuranceApplications.length > 0 ? 
          renderApplicationTable(props.insuranceApplications) : 
          "No pending insurance applications."
        }
        </div>

      </div>
      )
}

export default ApplicationsTable